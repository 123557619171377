import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from '@emotion/styled';
import Hero from '../components/hero';

const Section = styled('section')`
  margin-top: 0;
  padding-left: 12px;
  font-size: .8em;
`;

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Mike Milano - Dev Blog" />
        <Hero />
        <div style={{fontSize: `.9em`,margin: `10px auto`,width: `768px`}}>Latest Posts:</div>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.frontmatter.slug;
          return (
            <article key={node.frontmatter.slug}>
              <header>
                <h3>
                  <Link style={{ boxShadow: `none` }} to={node.frontmatter.slug}>
                    {title}
                  </Link>
                </h3>
                <p>{node.frontmatter.date}</p>
              </header>
              <Section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </Section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {published: {eq: true}}}) {
      edges {
        node {
          excerpt
          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
